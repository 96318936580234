<template>
  <button
    class="flex size-7 items-center justify-center rounded-full p-1.5"
    @click="addToCartProxy"
  >
    <IconCart class="size-full" />
  </button>
</template>

<script setup lang="ts">
import { getTranslatedProperty } from '@shopware-pwa/helpers-next'
import { toRefs } from 'vue'
import type { Schemas } from '#shopware'

const props = defineProps<{
  product: Schemas['Product']
}>()

const { product } = toRefs(props)
const { pushError, pushSuccess } = useNotifications()
const { addToCart: swAddToCart } = useAddToCart(product)
const { getErrorsCodes } = useCartNotification()
const { t } = useI18n()

const addToCartProxy = async () => {
  await swAddToCart()
  getErrorsCodes()?.forEach((element) => {
    pushError(t(`errors.${element.messageKey}`, { ...element }))
  })
  pushSuccess(
    t('cart.productAdded', {
      product: getTranslatedProperty(product.value, 'name'),
    })
  )
}
</script>
